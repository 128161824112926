// src/components/specific/WishList.jsx
import React from 'react';
import { Spinner, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import WishCard from './WishCard';
import LoadingWave from '../common/LoadingWave';
import '../../assets/styles/WishList.css';

const WishList = ({ wishes, onUpdateWish, loading, error, loadingMore, lastPageReached, refElement }) => {
  const { t } = useTranslation();

  return (
    <section className="wish-collage-container" ref={refElement}>
      {loading ? (
          <>
            <LoadingWave />
            <p style={{ textAlign: 'center', width: '100%'  }}>{t('common.loading_more')}</p>
          </>

        ) : error ? (
          <Alert variant="danger">
            {t('wishList.error_loading_wishes')}: {error.message}
          </Alert>

        ) : wishes.length > 0 ? (
          <div>
            <div className="wish-collage">
              {wishes.map((wish, index) => (
                <WishCard key={wish.id} wish={wish} onUpdateWish={onUpdateWish} />
              ))}
            </div>

            {/* Sentinela: espacio extra al final */}
            <div style={{ height: '200px', background: 'transparent' }} id="bottom-sentinel"></div>

            {loadingMore ? (
              <>
                <LoadingWave />
                <p style={{ textAlign: 'center', width: '100%'  }}>{t('common.loading_more')}</p>
              </>

            ) : lastPageReached ? (
              <div className="end-of-list-message">
                <hr className="end-line" />
                <p className="end-message">{t('wishList.that_all_for_now_come_back_later_for_more')}</p>
              </div>
              // <p className="end-message">&nbsp;</p>

            ) : null}
          </div>

        ) : (
          <p className="no-wishes-message">{t('wishList.no_wishes_to_show')}.</p>
        )}
    </section>
  );
};

export default WishList;