// src/services/wishService.js
import API from '../api';

const wishService = {
  getAllWishes: (page = 1) => API.get('/wishes', { params: { page } }),
  getWish: (id) => API.get(`/wish/${id}`),
  getTopWishes: () => API.get('/wishes/top'),
  getWishesCount: () => API.get('/wishes-count'),
  createWish: (wishData) => API.post('/wishes/create', wishData),
  publishWish: (wishId) => API.post(`/wishes/${wishId}/publish`),
  reactToWish: (wishId, reaction) => API.post(`/wishes/${wishId}/react`, reaction),
  searchWishes: (query, page = 1) => {
    return API.get('/search', { params: { q: query, page } });
  },
};

export default wishService;