// src/pages/HomePage.jsx
import React, { useEffect, useState, useRef, useCallback } from 'react';
import TopWishesMobileButton from '../components/specific/TopWishesMobileButton';
import verificationService from '../services/verificationService';
import { useLocation, useSearchParams } from 'react-router-dom';
import MessageModal from '../components/modals/MessageModal';
import ModalLoading from '../components/modals/ModalLoading';
import MainLayout from '../components/layout/MainLayout';
import TopWishes from '../components/specific/TopWishes';
import WishForm from '../components/specific/WishForm';
import WishList from '../components/specific/WishList';
import useWindowWidth from '../hooks/useWindowWidth';
import wishService from '../services/wishService';
import { useTranslation } from 'react-i18next';
import SEO from '../components/common/SEO';
import { debounce } from 'lodash';
import '../assets/styles/HomePage.css';

const HomePage = () => {
  // Estados para todos los deseos
  const { t } = useTranslation();
  const location = useLocation();
  const [wishes, setWishes] = useState([]);
  const [loadingWishes, setLoadingWishes] = useState(true);
  const [errorWishes, setErrorWishes] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const language = localStorage.getItem('language') || 'en';
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const verificationAttemptedRef = useRef(false);
  
  const [loadingModalWave, setLoadingModalWave] = useState(false);
  const [loadingModalWaveText, setLoadingModalWaveText] = useState(null);

  // Estados para los top deseos
  // const [topWishes, setTopWishes] = useState([]);
  // const [loadingTopWishes, setLoadingTopWishes] = useState(true);
  // const [errorTopWishes, setErrorTopWishes] = useState(null);

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageModalType, setMessageModalType] = useState('info');
  const [messageModalContent, setMessageModalContent] = useState('');

  const width = useWindowWidth();
  const isMobile = width < 768;
  
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('q') || '';

  const loadingMoreRef = useRef(false);
  const wishListRef = useRef(null);

  // useCallback para asegurar que loadMoreWishes no se recrea en cada render
  const loadMoreWishes = useCallback(async () => {
    if (currentPage >= lastPage || loadingMoreRef.current) return;

    console.log(`Cargando más deseos: Página ${currentPage + 1}`);
    loadingMoreRef.current = true;
    setLoadingMore(true);

    try {
      const nextPage = currentPage + 1;
      const response = await wishService.getAllWishes(nextPage);
      console.log('Respuesta de la API:', response.data);

      if (response.data.status) {
        const newWishes = response.data.data.wishes.data.filter(
          (newWish) => !wishes.some((wish) => wish.id === newWish.id)
        );
        setWishes((prev) => [...prev, ...newWishes]);
        setCurrentPage(response.data.data.wishes.current_page);
        setLastPage(response.data.data.wishes.last_page);
        console.log(`Página actualizada a ${response.data.data.wishes.current_page}`);
      }
    } catch (err) {
      console.error('Error cargando más deseos:', err);
    } finally {
      loadingMoreRef.current = false;
      setLoadingMore(false);
    }
  }, [currentPage, lastPage, wishes]);

  useEffect(() => {
    // Función para obtener todos los deseos
    const fetchAllWishes = async () => {
      try {
        setLoadingWishes(true);
        let response;

        if (searchQuery) {
          // Realizar búsqueda
          response = await wishService.searchWishes(searchQuery);
        } else {
          // Obtener todos los deseos
          response = await wishService.getAllWishes();
        }

        if (response.data.status) {
          setWishes(response.data.data.wishes.data);
          setCurrentPage(response.data.data.wishes.current_page);
          setLastPage(response.data.data.wishes.last_page);
        } else {
          setErrorWishes(new Error(t('common.error_loading_wishes')));
        }
      } catch (err) {
        setErrorWishes(err);
      } finally {
        setLoadingWishes(false);
      }
    };

    fetchAllWishes();
  }, [searchQuery, t]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const email = params.get('email');
    const token = params.get('token');

    const verifyEmail = async () => {
      try {
        const response = await verificationService.emailVerificationProcess({ email, token });
        if (response.data.status) {
          setLoadingModalWave(false);
          setMessageModalType('info');
          setMessageModalContent(t('email_verification.success_message') + ' - ' + t(`email_verification.${response.data.message}`));
        } else {
          setLoadingModalWave(false);
          setLoadingModalWaveText(null);
          setMessageModalType('error');
          setMessageModalContent(t('errors.error_message_on_email_verification') + ' - ' + response.data.message);
        }
      } catch (err) {
        const errors = err.response?.data?.message || 'unknown_error';
        setMessageModalType('error');
        setMessageModalContent(t('errors.error_message_while_verifying_email') + ' - ' + t(`errors.${errors}`));
      } finally {
        setLoadingModalWave(false);
        setLoadingModalWaveText(null);
        setShowMessageModal(true);
      }
    };

    if (email && token && !verificationAttemptedRef.current && location.pathname.includes('email-verification-process')) {
      setLoadingModalWave(true);
      setLoadingModalWaveText(t('email_verification.loading_message'));
      verificationAttemptedRef.current = true;
      verifyEmail();
    } else {
      console.log('La verificación del correo electrónico ya se ha intentado');
    }
    
  }, [location, t]);

  // Función para actualizar un deseo (por ejemplo, likes, hearts)
  const updateWish = (updatedWish) => {
    setWishes((prevWishes) =>
      prevWishes.map((wish) =>
        wish.id === updatedWish.id ? updatedWish : wish
      )
    );
    // setTopWishes((prevTopWishes) =>
    //   prevTopWishes.map((wish) =>
    //     wish.id === updatedWish.id ? updatedWish : wish
    //   )
    // );
  };

  // useEffect(() => {
  //   const handleScroll = debounce(() => {
  //     if (
  //       window.innerHeight + window.scrollY >=
  //       document.body.offsetHeight - 200
  //     ) {
  //       loadMoreWishes();
  //     }
  //   }, 200); // Debounce de 200ms

  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [loadMoreWishes]);
  useEffect(() => {
    const handleWishListScroll = debounce(() => {
      if (!wishListRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = wishListRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        console.log('Intento de scroll más allá del final. Cargando más...');
        loadMoreWishes();
      }
    }, 200);

    const container = wishListRef.current;

    if (container) {
      container.addEventListener('scroll', handleWishListScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleWishListScroll);
      }
    };
  }, [loadMoreWishes]);

  return (
    <MainLayout>

      <SEO
        titleKey="seo.homepage.title"
        descriptionKey="seo.homepage.description"
        keywordsKey="seo.homepage.keywords"
        lang={language}
        url={`${window.location.origin}/${language}`}
        image={`${window.location.origin}/images/homepage-banner.jpg`}
        siteTwitter="@andres_elias"
        authorTwitter="@andres_elias"
        localeAlternates={['en_US', 'es_ES']}
        structuredData={{
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          name: t('seo.homepage.title'),
          description: t('seo.homepage.description_structured_data'),
          url: `${window.location.origin}/${language}`,
          mainEntity: {
            '@type': 'ItemList',
            itemListElement: wishes.slice(0, 5).map((wish, index) => ({
              '@type': 'ListItem',
              position: index + 1,
              item: {
                '@type': 'CreativeWork',
                name: wish.content,
                author: {
                  '@type': 'Person',
                  name: wish.user?.name || 'Usuario anónimo',
                },
                datePublished: wish.created_at,
                interactionStatistic: [
                  {
                    '@type': 'InteractionCounter',
                    interactionType: 'https://schema.org/LikeAction',
                    userInteractionCount: wish.likes || 0,
                  },
                  {
                    '@type': 'InteractionCounter',
                    interactionType: 'https://schema.org/ReactAction',
                    userInteractionCount: wish.hearts || 0,
                  },
                ],
              },
            })),
          },
        }}
      />

      <div className="main-container">
        {/* Left Sidebar */}
        <aside className="left-sidebar">
          <WishForm />
        </aside>

        {/* Wish Collage */}
        
          <WishList 
            wishes={wishes} 
            onUpdateWish={updateWish} 
            loading={loadingWishes} 
            error={errorWishes} 
            loadingMore={loadingMore}
            lastPageReached={currentPage >= lastPage}
            refElement={wishListRef}
          />

        {/* Right Sidebar */}
        <aside className="right-sidebar">
          {!isMobile && (
            <TopWishes 
              // topWishes={topWishes}
              wishes={wishes} 
              onUpdateWish={updateWish} 
              // loading={loadingTopWishes} 
              loading={loadingWishes}
              // error={errorTopWishes} 
              error={errorWishes}
            />
          )}

          {isMobile && (
            <TopWishesMobileButton
              wishes={wishes}
              onUpdateWish={updateWish}
              loading={loadingWishes}
              error={errorWishes}
            />
          )}
        </aside>

        <MessageModal
          show={showMessageModal}
          type={messageModalType}
          message={messageModalContent}
          onClose={() => setShowMessageModal(false)}
        />

        <ModalLoading
          show={loadingModalWave}
          message={loadingModalWaveText}
          barSize={8}
        />

      </div>
    </MainLayout>
  );
};

export default HomePage;