import React, { useState, useEffect } from 'react';

import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import '../../assets/styles/SocialNetworksShare.css';

const SocialNetworksShare = () => {

	const { t } = useTranslation();
	const language = localStorage.getItem('language') || 'en';
	const [wishId, setWishId] = useState(null);
	const { user } = useAuth();

	useEffect(() => {
    if (user && Array.isArray(user.wishes)) {
      const existingWish = user.wishes.find(wish => wish.status === 'published');
      if (existingWish) {
        setWishId(existingWish.id);
      }
    }
  }, [user]);

	
  return (
    <div className="share-section">
	  <p>{t('paymentSuccess.share_your_wish')}:</p>
	  <div className="share-buttons">
	    {/* Twitter */}
	    <a
	      href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
	        t('paymentSuccess.share_text', { wishLink: `${window.location.origin}/${language}/wish/${wishId}` })
	      )}`}
	      target="_blank"
	      rel="noopener noreferrer"
	      className="share-btn twitter"
	    >
	      <i className="bi bi-twitter"></i> Twitter
	    </a>

	    {/* Facebook */}
	    <a
	      href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
	        `${window.location.origin}/${language}/wish/${wishId}`
	      )}`}
	      target="_blank"
	      rel="noopener noreferrer"
	      className="share-btn facebook"
	    >
	      <i className="bi bi-facebook"></i> Facebook
	    </a>

	    {/* WhatsApp */}
	    <a
	      href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
	        t('paymentSuccess.share_text', { wishLink: `${window.location.origin}/${language}/wish/${wishId}` })
	      )}`}
	      target="_blank"
	      rel="noopener noreferrer"
	      className="share-btn whatsapp"
	    >
	      <i className="bi bi-whatsapp"></i> WhatsApp
	    </a>

	    {/* Telegram */}
	    <a
	      href={`https://t.me/share/url?url=${encodeURIComponent(
	        `${window.location.origin}/${language}/wish/${wishId}`
	      )}&text=${encodeURIComponent(
	        t('paymentSuccess.share_text', { wishLink: `${window.location.origin}/${language}/wish/${wishId}` })
	      )}`}
	      target="_blank"
	      rel="noopener noreferrer"
	      className="share-btn telegram"
	    >
	      <i className="bi bi-telegram"></i> Telegram
	    </a>

	    {/* LinkedIn */}
	    <a
	      href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
	        `${window.location.origin}/${language}/wish/${wishId}`
	      )}&title=${encodeURIComponent(
	        t('paymentSuccess.share_text', { wishLink: `${window.location.origin}/${language}/wish/${wishId}` })
	      )}`}
	      target="_blank"
	      rel="noopener noreferrer"
	      className="share-btn linkedin"
	    >
	      <i className="bi bi-linkedin"></i> LinkedIn
	    </a>
	  </div>
	</div>
  );
};

export default SocialNetworksShare;