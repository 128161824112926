// src/components/WishForm.jsx
import React, { useState, useEffect, useCallback } from 'react';

import WishConfirmationModal from '../modals/WishConfirmationModal';
import PaymentOptionsModal from '../modals/PaymentOptionsModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import stripeService from '../../services/stripeService';
import paypalService from '../../services/paypalService';
import AuthPromptModal from '../modals/AuthPromptModal';
import wishService from '../../services/wishService';
import RegisterModal from '../modals/RegisterModal';
import { CONSTANTS } from '../../config/constants';
import ModalLoading from '../modals/ModalLoading';
import ProfileModal from '../modals/ProfileModal';
import LoadingModal from '../modals/LoadingModal';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import LoginModal from '../modals/LoginModal';
import GoogleAd from '../common/GoogleAd';
import useAuth from '../../hooks/useAuth';
import HashtagText from "./HashtagText";
import Avatar from '../common/Avatar';
import WishEditor from './WishEditor';
import '../../assets/styles/WishForm.css';

const stripePromise = loadStripe(CONSTANTS.STRIPE_PUBLISHABLE_KEY);

const WishForm = () => {
  const { user, logout, loading, loadingText } = useAuth();
  const { t } = useTranslation();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [triggerPaymentPage, setTriggerPaymentPage] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showWishModal, setShowWishModal] = useState(false);
  const [showAuthPromptModal, setShowAuthPromptModal] = useState(false);
  const [wishText, setWishText] = useState('');
  const [wishId, setWishId] = useState(null);
  // const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const navigate = useNavigate();
  const [showPaymentOptionsModal, setShowPaymentOptionsModal] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [copied, setCopied] = useState(false);
  const location = useLocation();

  const language = localStorage.getItem('language') || 'en';

  useEffect(() => {
    if (user && Array.isArray(user.wishes)) {
      // Verificamos si hay un deseo en estado "created" o "published"
      const existingWish = user.wishes.find(wish => wish.status === 'created' || wish.status === 'published');

      if (existingWish) {
        setWishText(existingWish.content);
        setIsPublished(existingWish.status === 'published');
        setWishId(existingWish.id);
      }
    }
  }, [user]);

  const formatJoinDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', { month: 'short', day: 'numeric', year: 'numeric' });
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(false), 10000); // 10 segundos
      return () => clearTimeout(timer);
    }
  }, [error]);

  const handlePublishClick = () => {
    if (wishText.trim() === '') {
      setError(true);
      return;
    }
    setError(false);

    //if (user?.gifted_wishes_as_receiver?.length > 0) {
      setShowWishModal(true);
    //} else {
    //  setShowPaymentOptionsModal(true);
    //}
  };

  const handleProceedToPayment = useCallback(async () => {
    if (!user) {
      setShowAuthPromptModal(true);
      setSelectedPaymentMethod('stripe');
      return;
    }

    try {
      setShowLoading(true);
      const responseWishCreation = await wishService.createWish({ content: wishText });
      if (responseWishCreation.status === false) {
        throw new Error('Error al crear el deseo');
      } else {
        setShowWishModal(false);
        setTriggerPaymentPage(false);
      
        const stripe = await stripePromise;
        const response = await stripeService.createCheckoutSession({ wish: wishText });
        const session = response.data;

        const { error } = await stripe.redirectToCheckout({ sessionId: session.data.id });
        if (error) console.error("Error al redirigir a Stripe:", error.message);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'unknown_error_trying_to_create_wish';
      setErrorMessage( t(`common.${errorMessage}`) );
      setError(true);
    } finally{
      setShowLoading(false);
    }
  }, [user, wishText]);

  const handleLoginClick = () => {
    setShowAuthPromptModal(false);
    setShowLoginModal(true);
    setTriggerPaymentPage(true);
  };

  const handleRegisterClick = () => {
    setShowAuthPromptModal(false);
    setShowRegisterModal(true);
    setTriggerPaymentPage(true);
  };

  // const handleEmojiClick = (event, emojiObject) => {
  //   setWishText(wishText + emojiObject.emoji);
  //   setShowEmojiPicker(false);
  // };

  const handleGoToConfig = () => {
    navigate(`/${language}/config`);
  };

  const handleProceedToPayPal = useCallback(async () => {
    if (!user) {
      setShowAuthPromptModal(true);
      setSelectedPaymentMethod('paypal');
      return;
    }

    try {
      setShowLoading(true);
      const responseWishCreation = await wishService.createWish({ content: wishText });
      if (responseWishCreation.status === false) {
        throw new Error('Error al crear el deseo');
      } else {
        // Crear la orden de PayPal
        const response = await paypalService.createCheckoutSession({ wish: wishText });

        if (response.data && response.data.data && response.data.data.approval_url) {
          // Redireccionar al usuario a la URL de aprobación de PayPal
          window.location.href = response.data.data.approval_url;
        } else {
          throw new Error('No se recibió la URL de aprobación de PayPal.');
        }
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'unknown_error_trying_to_create_wish';
      setErrorMessage( t(`common.${errorMessage}`) );
      setError(true);
    } finally {
      setShowLoading(false);
    }
  }, [user, wishText]);

  const handleSelectPayment = async (paymentMethod) => {
    setShowPaymentOptionsModal(false);
    setSelectedPaymentMethod(paymentMethod);

    if (paymentMethod === 'stripe') {
      handleProceedToPayment();

    } else if (paymentMethod === 'paypal') {
      handleProceedToPayPal();

    } else if (paymentMethod === 'crypto') {
      // Implementa la lógica para criptomonedas cuando esté disponible

    }
  };

  useEffect(() => {
    if (!user) {
      setWishText('');
      setIsPublished(false);
    }
  }, [user]);

  useEffect(() => {
    if (user && selectedPaymentMethod && triggerPaymentPage) {
      if (selectedPaymentMethod === 'stripe') {
        handleProceedToPayment();
      } else if (selectedPaymentMethod === 'paypal') {
        handleProceedToPayPal();
      }
      setSelectedPaymentMethod(null);
    }
  }, [user, selectedPaymentMethod, triggerPaymentPage, handleProceedToPayment, handleProceedToPayPal]);

  useEffect(() => {
    if(!user) {
      const pathSegments = location.pathname.split('/').filter(Boolean);
      if (pathSegments.length >= 2 && pathSegments[1] === 'register') {
        setShowRegisterModal(true);
      } else {
        setShowRegisterModal(false);
      }
    }else{
      setShowRegisterModal(false);
    }
  }, [location, user]);



  const handleNextStepToPublish = () => {
    setShowWishModal(false);

    if (wishText.trim() === '') {
      setError(true);
      return;
    }
    setError(false);

    if (user?.gifted_wishes_as_receiver?.length > 0) {
      handleProceedToPublish(true);
    } else {
      setShowPaymentOptionsModal(true);
    }
  }



  const handleProceedToPublish = useCallback(async () => {
    if (!user) {
      setShowAuthPromptModal(true);
      return;
    }

    try {
      setShowLoading(true);
      // Crear el deseo
      const responseWishCreation = await wishService.createWish({ content: wishText });
      if (responseWishCreation.data.status === false) {
        throw new Error('Error al crear el deseo');
      }
      const createdWish = responseWishCreation.data.data.wish;

      // Publicar el deseo (sin pago, ya que el usuario tiene un deseo regalado)
      const responsePublish = await wishService.publishWish(responseWishCreation.data.data.wish.id);
      if (!responsePublish.data.status) {
        setShowWishModal(false);
        throw new Error('Error al publicar el deseo');
      }

      setIsPublished(true);
      setShowWishModal(false);
      setWishText(createdWish.content);

    } catch (error) {
      console.log({ error });
      const errorMessage = error.response?.data?.message || 'unknown_error_trying_to_publish_wish';
      setErrorMessage( t(`common.${errorMessage}`) );
      setError(true);
    } finally {
      setShowLoading(false);
    }
  }, [user, wishText, t]);


  const handleCopyLink = async () => {
    const language = localStorage.getItem('language') || 'en';
    const urlToCopy = `${window.location.origin}/${language}/wish/${wishId}`;

    try {
      await navigator.clipboard.writeText(urlToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 5000);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <>
      {user ? (
        <>
          {/* Sección de Perfil Completo en Desktop */}
          <div className="user-info-box">
            <div className="user-info-header">
              <div className="user-avatar">
                <Avatar
                  url={user.data_user.avatar_url}
                  alt={user.name}
                />
              </div>
              <div className="user-details">
                <h5>{user.name || 'Usuario'}</h5>
                <p>{t('wishForm.member_since_label')}</p> 
                <p>{user.created_at ? formatJoinDate(user.created_at) : t('wishForm.registered_at_label')}</p>
              </div>
            </div>
            <div className="user-actions">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-config">{t('wishForm.configuration')}</Tooltip>}
              >
                <button onClick={handleGoToConfig} style={{ padding: 0 }}>
                  <i className="bi bi-gear"></i>
                </button>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-logout">{t('common.logout')}</Tooltip>}
              >
                <button onClick={logout} style={{ padding: 0 }}>
                  <i className="bi bi-box-arrow-right"></i>
                </button>
              </OverlayTrigger>
            </div>
          </div>

          {/* Botón Flotante de Perfil para Móviles */}
          <div 
            className="profile-float-btn" 
            onClick={() => setShowProfileModal(true)}
            role="button"
            tabIndex="0"
            onKeyPress={(e) => e.key === 'Enter' && setShowProfileModal(true)}
          >
            <Avatar
              url={user.data_user.avatar_url}
              alt={user.name}
            />
          </div>

          {/* Modal de Perfil para Móviles */}
          <ProfileModal
            show={showProfileModal}
            onClose={() => setShowProfileModal(false)}
            user={user}
            handleGoToConfig={handleGoToConfig}
            logout={logout}
          />
        </>
      ) : (
        <div className="auth-actions">
          <button onClick={() => setShowLoginModal(true)} className="auth-btn login">
            {t('common.login')}
          </button>
          <button onClick={() => setShowRegisterModal(true)} className="auth-btn register">
            {t('common.register')}
          </button>
        </div>
      )}

      <h5>{isPublished ? t('wishForm.you_wish_is') : t('wishForm.publish_your_wish')}</h5>
      {isPublished ? (
        <>

          <HashtagText text={wishText} />

          <div className="reactions-readonly readonly">
            <div className="reaction-buttons-readonly">
              <div className="reaction-btn-readonly like-btn-readonly">
                <i className="bi bi-hand-thumbs-up"></i> {user.wishes?.[0]?.likes || 0}
              </div>
              <div className="reaction-btn-readonly heart-btn-readonly">
                <i className="bi bi-heart"></i> {user.wishes?.[0]?.hearts || 0}
              </div>
              <div className="reaction-btn-readonly dislike-btn-readonly">
                <i className="bi bi-hand-thumbs-down"></i> {user.wishes?.[0]?.dislikes || 0}
              </div>
            </div>
          </div>


          {/* Sección de compartir */}
          <div className="share-single-button-section">
            {!copied ? (
              <button className="share-single-btn" onClick={handleCopyLink}>
                {t('wishList.share_single_button_text')}
              </button>
            ) : (
              <p className="copied-message">
                {t('wishList.copied_message')}
              </p>
            )}
          </div>


          {error && <p className="error-message">{errorMessage}</p>}
        </>
      ) : (
        // <>
        //   <textarea 
        //     id="wishInput" 
        //     rows="5" 
        //     placeholder={t('wishForm.write_your_wish')}
        //     value={wishText} 
        //     onChange={(e) => setWishText(e.target.value)} 
        //   />
        //   {error && <p className="error-message">{t('wishForm.write_your_wish_before_publish')}</p>}
        //   <button id="publishBtn" onClick={handlePublishClick}>{t('wishForm.publish_by_1_usd')}</button>
        // </>
        <WishEditor 
          wishText={wishText} 
          setWishText={setWishText} 
          error={error} 
          handlePublishClick={handlePublishClick} 
        />
      )}


      {/* Banner de Google Ad */}
      <div style={{ marginTop: '20px' }}>
        <GoogleAd
          adSlot="3017944999"
          style={{ width: '250px', height: '250px', margin: '0 auto' }}
        />
      </div>



      {/* Modales Existentes */}
      <LoginModal show={showLoginModal} onClose={() => setShowLoginModal(false)} />

      <RegisterModal show={showRegisterModal} onClose={() => setShowRegisterModal(false)} />

      <WishConfirmationModal 
        show={showWishModal} 
        wish={wishText} 
        onClose={() => setShowWishModal(false)} 
        onProceed={handleNextStepToPublish} 
      />

      <AuthPromptModal
        show={showAuthPromptModal}
        onClose={() => setShowAuthPromptModal(false)}
        onLogin={handleLoginClick}
        onRegister={handleRegisterClick}
      />

      <LoadingModal show={showLoading} />

      <PaymentOptionsModal
        show={showPaymentOptionsModal}
        onClose={() => setShowPaymentOptionsModal(false)}
        onSelectPayment={handleSelectPayment}
      />

      <ModalLoading
        show={loading}
        message={loadingText}
        barSize={8}
      />

    </>
  );
};

export default WishForm;