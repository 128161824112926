// src/pages/PaymentPaypalSuccess.jsx
import React, { useEffect, useState } from 'react';
import { Toast, ToastContainer, OverlayTrigger, Tooltip, InputGroup, FormControl, Button } from 'react-bootstrap';
import TopWishesMobileButton from '../components/specific/TopWishesMobileButton';
import { Navigate, useSearchParams, useNavigate, Link } from 'react-router-dom';
import ModalLoading from '../components/modals/ModalLoading'; 
import LoadingModal from '../components/modals/LoadingModal';
import MainLayout from '../components/layout/MainLayout';
import TopWishes from '../components/specific/TopWishes';
import WishForm from '../components/specific/WishForm';
import paypalService from '../services/paypalService';
import { useTranslation, Trans } from 'react-i18next';
import useWindowWidth from '../hooks/useWindowWidth';
import authService from '../services/authService';
import wishService from '../services/wishService';
import SEO from '../components/common/SEO';
import useAuth from '../hooks/useAuth';
import '../assets/styles/StaticPageContent.css';

const PaymentPaypalSuccess = () => {
  const { t } = useTranslation();
  const { user, refreshUser } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const orderId = searchParams.get('token');
  const wishId = searchParams.get('wish_id');
  const [wishes, setWishes] = useState([]);
  const [loadingWishes, setLoadingWishes] = useState(true);
  const [errorWishes, setErrorWishes] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const width = useWindowWidth();
  const isMobile = width < 768;
  const language = localStorage.getItem('language') || 'en';
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [timeoutReached, setTimeoutReached] = useState(false);
  const [verifiedPayment, setVerifiedPayment] = useState(false);
  const [errorOnPayment, setErrorOnPayment] = useState(false);

  useEffect(() => {
    const verifyPayment = async () => {
      if (!orderId) {
        setError(true);
        setMessage(t('paymentSuccess.ivalid_order'));
        setLoading(false);
        return;
      }

      try {
        // Verificar y capturar el pago en el backend
        const response = await paypalService.verifyAndSavePayment({ order_id: orderId, wish_id: wishId });

        if (response.data && response.data.status) {
          setMessage(response.data.message || t('paymentSuccess.payment_received_successfully'));

          // Refrescar los datos del usuario para obtener el deseo actualizado
          await refreshUser();
          setVerifiedPayment(true);
        } else {
          setError(true);
          setMessage(response.data.message || t('paymentSuccess.error_processing_payment'));
        }
      } catch (err) {
        const errorMessage = err.message || t('paymentSuccess.error_processing_payment');
        const responseMessage = err.response?.data?.message;
        const combinedMessage = responseMessage
          ? `${errorMessage} -> ${ t('error.'+responseMessage) }`
          : errorMessage;

        setErrorOnPayment(true);

      } finally {
        setLoading(false);
      }
    };

    verifyPayment();
  }, [orderId, refreshUser]);


  useEffect(() => {
    // Función para obtener todos los deseos
    const fetchAllWishes = async () => {
      try {
        setLoadingWishes(true);
        const response = await wishService.getAllWishes();
        if (response.data.status) {
          setWishes(response.data.data.wishes.data);
        } else {
          setErrorWishes(new Error(t('common.error_loading_wishes')));
        }
      } catch (err) {
        setErrorWishes(err);
      } finally {
        setLoadingWishes(false);
      }
    };

    fetchAllWishes();
  }, []);

  // Función para actualizar un deseo (por ejemplo, likes, hearts)
  const updateWish = (updatedWish) => {
    setWishes((prevWishes) =>
      prevWishes.map((wish) =>
        wish.wishNumber === updatedWish.wishNumber ? updatedWish : wish
      )
    );
  };

  const handleGoHome = () => {
    navigate('/');
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setToastMessage(t('common.copied_to_clipboard'));
      setShowToast(true);
    } catch (err) {
      console.error('Failed to copy!', err);
      setToastMessage(t('common.copy_failed'));
      setShowToast(true);
    }
  };


  if (loading && !timeoutReached) {
    // Mostrar el modal de carga mientras se está cargando y aún no pasaron 30 seg.
    return <ModalLoading show={true} message={t('common.loading_user_info')} />;
  }
  
  if (!verifiedPayment && user && !errorOnPayment) {
    return <ModalLoading show={true} message={t('common.verifiying_payment')} />;
  }

  if (!user || errorOnPayment) {
    return <Navigate to={`/${language}/`} replace />
  }

  return (
    <MainLayout>

      <SEO 
        titleKey="seo.payment_paypal_success.title"
        descriptionKey="seo.payment_paypal_success.description"
        keywordsKey="seo.payment_paypal_success.keywords"
        lang={language}
        url={`${window.location.origin}/${language}/payment/paypal/success`}
        image={`${window.location.origin}/images/payment-success-banner.jpg`}
        siteTwitter="@andres_elias"
        authorTwitter="@andres_elias"
        localeAlternates={['en_US', 'es_ES']}
        structuredData={{
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          name: t('seo.payment_paypal_success.title'),
          description: t('seo.payment_paypal_success.description'),
          url: `${window.location.origin}/${language}/payment/paypal/success`,
          mainEntity: {
            '@type': 'CreativeWork',
            name: t('seo.payment_paypal_success.title'),
            text: t('seo.payment_paypal_success.description'),
          },
          potentialAction: [
            {
              '@type': 'ShareAction',
              target: [
                `https://twitter.com/intent/tweet?text=Estoy%20participando%20en%20el%20sorteo%20de%20$10,000!%20Apoya%20mi%20deseo%20aquí:%20${window.location.origin}/wish/${orderId}`,
                `https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/wish/${orderId}`,
                `https://api.whatsapp.com/send?text=Estoy%20participando%20en%20el%20sorteo%20de%20$10,000!%20Apoya%20mi%20deseo%20aquí:%20${window.location.origin}/wish/${orderId}`,
              ],
              name: t('common.share_your_wish_on_social_networks'),
            },
          ],
        }}
      />

      <div className="main-container">
        {/* Left Sidebar */}
        <aside className="left-sidebar hide-on-mobile">
          <WishForm />
        </aside>

        {/* Center Content (Payment Success Message) */}
        <section className="static-page-content">
          {loading ? (
            <LoadingModal show={true} />
          ) : (
            <div className="payment-success">
              <h2>{t('paymentSuccess.wish_published')}</h2>
              <p>{message}</p>
              
              <Trans i18nKey="paymentSuccess.message.text1">
                <p>Ahora estás participando por un premio de <strong>$10,000</strong>.</p>
              </Trans>

              <p>
                <Trans i18nKey="paymentSuccess.message.text2">
                  <Link to={`/${language}/prize-and-raffle-base`} className="rules-link" />
                </Trans>
              </p>
              
              <p>
                {t('paymentSuccess.message.text3')}
              </p>

              <div className="share-section">
                <p>{t('paymentSuccess.share_your_wish')}:</p>
                <div className="share-buttons">
                  {/* Twitter */}
                  <a
                    href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                      t('paymentSuccess.share_text', { wishLink: `${window.location.origin}/${language}/wish/${wishId}` })
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="share-btn twitter"
                  >
                    <i className="bi bi-twitter"></i> Twitter
                  </a>

                  {/* Facebook */}
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                      `${window.location.origin}/${language}/wish/${wishId}`
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="share-btn facebook"
                  >
                    <i className="bi bi-facebook"></i> Facebook
                  </a>

                  {/* WhatsApp */}
                  <a
                    href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                      t('paymentSuccess.share_text', { wishLink: `${window.location.origin}/${language}/wish/${wishId}` })
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="share-btn whatsapp"
                  >
                    <i className="bi bi-whatsapp"></i> WhatsApp
                  </a>

                  {/* Telegram */}
                  <a
                    href={`https://t.me/share/url?url=${encodeURIComponent(
                      `${window.location.origin}/${language}/wish/${wishId}`
                    )}&text=${encodeURIComponent(
                      t('paymentSuccess.share_text', { wishLink: `${window.location.origin}/${language}/wish/${wishId}` })
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="share-btn telegram"
                  >
                    <i className="bi bi-telegram"></i> Telegram
                  </a>

                  {/* LinkedIn */}
                  <a
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
                      `${window.location.origin}/${language}/wish/${wishId}`
                    )}&title=${encodeURIComponent(
                      t('paymentSuccess.share_text', { wishLink: `${window.location.origin}/${language}/wish/${wishId}` })
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="share-btn linkedin"
                  >
                    <i className="bi bi-linkedin"></i> LinkedIn
                  </a>
                </div>
              </div>

              {/* Nueva Sección de Referidos */}
              {user?.data_user?.referral_code && (
                <div className="referral-section mt-4 p-3 border rounded">
                  <h5>{t('paymentSuccess.referral_title')}</h5>
                  <p>{t('paymentSuccess.referral_description')}</p>
                  <InputGroup className="mb-3">
                    <FormControl
                      readOnly
                      value={`${window.location.origin}/${language}/?referral_code=${user?.data_user?.referral_code || ''}`}
                      aria-label="Referral Link"
                      aria-describedby="referral-link"
                    />
                    <Button 
                      variant="outline-secondary" 
                      onClick={() => copyToClipboard(`${window.location.origin}/${language}/?referral_code=${user?.data_user?.referral_code || ''}`)}
                    >
                      <i className="bi bi-clipboard"></i> {t('common.copy')}
                    </Button>
                  </InputGroup>
                  <p className="text-muted">
                    {t('paymentSuccess.your_referral_code_is')}: <strong>{`${user?.data_user?.referral_code}`}</strong>
                  </p>
                </div>
              )}

              <button onClick={handleGoHome} className="continue-btn">
                {t('common.back_to_homepage')}
              </button>
            </div>
          )}
        </section>


        {/* Right Sidebar */}
        <aside className="right-sidebar">
          {!isMobile && (
            <TopWishes
              wishes={wishes} 
              onUpdateWish={updateWish}
              loading={loadingWishes}
              error={errorWishes}
            />
          )}

          {isMobile && (
            <TopWishesMobileButton
              wishes={wishes}
              onUpdateWish={updateWish}
              loading={loadingWishes}
              error={errorWishes}
            />
          )}
        </aside>

        <ToastContainer position="top-end" className="p-3">
          <Toast
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={3000}
            autohide
            bg="success"
          >
            <Toast.Body className="text-white">{toastMessage}</Toast.Body>
          </Toast>
        </ToastContainer>

      </div>
    </MainLayout>
  );
};

export default PaymentPaypalSuccess;